<template>
  <div class="error-page flex-column">
    <!-- <headers></headers> -->
    <div class="error-page-wrapper flex-align-between">
      <img src="@/assets/images/404.png" alt="" class="logo404">
      <div class="describe">
        <p class="text">抱歉！您所访问的页面不存在，请重新加载！</p>
        <div class="btn-group">
          <button class="csp" @click="$router.go(0)"><i class="el-icon-refresh-right"></i>刷新网页</button>
          <button class="csp" @click="$router.push('/home')"><i class="el-icon-arrow-left"></i> 返回首页</button>
        </div>
      </div>

    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
  export default {

  }
</script>

<style lang="less" scoped>
  .error-page {
    height: 100vh;
    line-height: 1;

    .error-page-wrapper {
      flex: 1;
      width: 990px;
      margin: 0 auto;
      padding: 0 111px 0 91px;
      box-sizing: border-box;
    }

    .logo404 {
      width: 425px;
    }

    .text {
      width: 289px;
      line-height: 37px;
      font-size: 22px;
      color: #999999;
    }

    .btn-group {
      margin-top: 32px;

      button {
        font-size: 20px;

        i {
          margin-right: 7px;
          font-size: 19px;
        }

        &:first-child {
          margin-right: 60px;
        }
      }
    }

    @media screen and (min-width:1250px) {
      .error-page-wrapper {
        width: 1200px;
        padding: 0 124px 0 109px;
      }

      .logo404 {
        width: 511px;
      }

      .text {
        width: 348px;
        line-height: 45px;
        font-size: 26px;
      }

      .btn-group {
        margin-top: 42px;

        button {
          font-size: 23px;

          i {
            margin-right: 9px;
            font-size: 23px;
          }

        }
      }
    }
  }
</style>